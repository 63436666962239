/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-31",
    versionChannel: "nightly",
    buildDate: "2024-10-31T00:06:43.670Z",
    commitHash: "8f4f9cc520aaad99aae15eef5d5c39a83a0fd441",
};
